<template>
  <v-container fluid align-start fill-height class="pt-0">
    <MainModal
        :main="{ component: 'FlatNote', title: 'Журнал коментарів / дзвінків' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_flat_notes)"
        :modal="show_flat_notes_dialog"
        @updateItemModal="flatNotesUpdateItemModal"
    />
    <v-row :justify="short_form ? 'start' : 'center'" style="height: 100%">
      <v-col cols="12" :class="short_form ? 'px-0' : 'pa-1 pt-2'">
        <v-card :tile="!short_form" elevation="1" style="height: 100%">
          <v-card-title class="pa-0 pr-1" v-if="!short_form">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Журнал коментарів / дзвінків
              </v-toolbar-title>
              <v-spacer/>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openFlatNotesCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити новий запис у журналі коментарів/дзвінків</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              :headers="short_form ? tableHeaderShort : tableHeader"
              :items="items"
              :single-select="true"
              style="height: calc(100% - 40px)"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :disable-pagination="short_form"
              :dense="short_form"
              :hide-default-footer="short_form"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-full event-logger-table"
              @click:row="onFlatNotesItemClick"
          >
            <template v-slot:item.owner_name="{ item }">
              {{ item.owner_name }}
            </template>
            <template v-slot:item.icon="{ item }">
              <v-icon
                  :color="getFlatNoteType(item.flat_note_type, 'color')"
                  :size="22"
              >
                {{ getFlatNoteType(item.flat_note_type, 'icon') }}
              </v-icon>
            </template>

            <template v-slot:item.flat_note_type="{ item }">
              <span class="font-weight-medium">
                {{ getFlatNoteType(item.flat_note_type, 'text') }}
              </span>
            </template>

            <template v-slot:item.flat_note_result_type="{ item }">
              <v-chip small :color="getFlatNoteResultType(item.flat_note_result_type, 'color')">
                {{ getFlatNoteResultType(item.flat_note_result_type, 'text') }}
              </v-chip>
            </template>

            <template v-slot:item.create_date="{ item }">
              <span>
                {{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
              </span>
            </template>

            <template v-slot:item.note_date="{ item }">
              <span>
                {{ item.note_date | formatDate('DD.MM.YYYY') }}
              </span>
            </template>
            <template v-slot:item.notes="{ item }">
              <span v-if="item.notes">
                {{ item.notes }}
              </span>
              <span v-else>
                Нотатки відсутні
              </span>
            </template>
            <template v-slot:item.date_to="{ item }">
              <span>
                {{ item.date_to | formatDate('DD.MM.YYYY') }}
              </span>
            </template>
            <template v-slot:item.sum="{ item }">
              <span v-if="item.sum !== null">
                {{ item.sum | formatNumber }}
              </span>
              <span v-else>
                Відсутня
              </span>
            </template>
            <template v-slot:item.disable="{ item }">
              <v-simple-checkbox :value="item.disable" color="success" class="mt-0"/>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import flatNoteAPI from "@/utils/axios/flat_notes";
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {getCurrentDate, getFlatNoteType, getFlatNoteResultType} from "@/utils/icons";

export default {
  name: "FlatNotes",
  props: ['person_hash', 'short_form'],
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters(
        {
          flat_current: 'getFlat',
        }
    )
  },
  data() {
    return {
      items: [],
      tableHeader: [
        { text: '', value: 'icon', width: 24 },
        { text: 'Тип запису', value: 'flat_note_type', width: 140 },
        { text: 'Результат', value: 'flat_note_result_type', width: 160 },
        { text: 'Дата', value: 'note_date', width: 140 },
        { text: 'Дата до', value: 'date_to', width: 130 },
        { text: 'Відкл.', value: 'disable', width: 90 },
        { text: 'Сума', value: 'sum', width: 110 },
        { text: 'Текст', value: 'notes'},
        { text: 'Автор', value: 'owner_name', width: 140 },
      ],
      tableHeaderShort: [
        { text: '', value: 'icon', width: 24 },
        { text: 'Дата', value: 'note_date', width: 140 },
        { text: 'Текст', value: 'notes'},
      ],
      selected_flat_notes: {},
      show_flat_notes_dialog: false
    }
  },
  methods: {
    getCurrentDate,
    getFlatNoteType,
    getFlatNoteResultType,
    fetch() {
      flatNoteAPI.get_all(this.person_hash)
          .then(response => response.data)
          .then(data => {
            this.items = data
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          });
    },
    flatNotesUpdateItemModal(payload) {
      this.show_flat_notes_dialog = false
      this.selected_flat_notes = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.fetch()
          this.$emit('afterFetch', true)
        }
      }
    },
    openFlatNotesCreateDialog() {
      this.selected_flat_notes = {
        flat_id: this.flat_current.id,
        note_date: this.getCurrentDate(),
        flat_note_type: 'comment'
      }
      this.show_flat_notes_dialog = true
    },
    onFlatNotesItemClick(payload) {
      this.selected_flat_notes = JSON.parse(JSON.stringify(payload))
      this.show_flat_notes_dialog = true
    },
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">

</style>